<template>
    <div>
        <howToBuy />
    </div>
</template>
<script>
import howToBuy from '../components/widgets/howToBuy.vue';

export default {
    name: 'howToBuyView',

    components: {
        howToBuy
    }
}
</script>
